.photo-album {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0.75rem 0;
  width: 75%;
  /* border: 1px solid var(--mainWhite); */
}

.toggleable-header {
  width: 100%;
  margin-bottom: 2rem;
  cursor: pointer;
}

.toggleable-header-container {
  width: 100%;
}

.photo-album .expand-icon {
  color: var(--mainWhite);
  cursor: pointer;
  z-index: 1;
  width: 42px;
  margin-top: 0.25rem;
  margin-right: 0.5rem;
  margin-left: -0.4rem;
  transform: rotate(0deg);
  transition: all 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
  -webkit-transition: all 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
}

.photo-album .expand-icon.open {
  transform: rotate(90deg);
}

.photo-album .title {
  font-size: 2rem;
  font-weight: normal;
  color: var(--mainWhite);
  margin-top: 1rem;
  margin-bottom: 1rem;
  z-index: 1;
  text-align: left;
  width: 100%;
}

.photo-album .divider {
  width: 100%;
  height: 1px;
  background-color: var(--mainWhite);
  z-index: 1;
  animation: none;
}

.album-masonry-container {
  width: 100%;
  transition: all 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
  -webkit-transition: all 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
  overflow: hidden;
}

.photo-album .photo-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 1;
}

.responsive-masonry {
  width: 100%;
}

.masonry {
  width: 100%;
}

.photo-album .photo-wrapper {
  /* padding: 1rem; */
  z-index: 1;
  width: 100%;
}

.photo-album .photo {
  object-fit: cover;
  cursor: pointer;
  display: flex;
  width: 100%;
  opacity: 1;
}

.photo-album .photo.blurhash {
  position: absolute !important;
  left: 0;
  top: 0;
  opacity: 1;
  transition: all 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
  -webkit-transition: all 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
  animation-delay: 500ms;
}

.photo-album .photo.blurhash.loaded {
  opacity: 0;
}

/* .photo-placeholder {
  width: 100%;
  aspect-ratio: 3/4;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 0;
  animation: 1.25s flicker infinite;
} */

@media screen and (max-width: 650px) {
  .photo-album .title {
    font-size: 1.5rem;
  }
}

@keyframes flicker {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 0.60;
  }

  100% {
    opacity: 0.3;
  }
}