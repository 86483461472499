.image-viewer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  pointer-events: none;
  transition: all 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
  -webkit-transition: all 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
  padding: 1rem;
}

.image-viewer.show {
  opacity: 1;
  pointer-events: all;
}

.image-viewer__image {
  max-width: 100%;
  max-height: 90%;
  object-fit: contain;
  cursor: pointer;
  transition: all 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
  -webkit-transition: all 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
}