.blog-entry-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  width: 300px;
  height: 300px;
  transition: ease 0.3s opacity, 300ms cubic-bezier(0.445, 0.050, 0.550, 0.950) transform !important;
  opacity: 1 !important;
  overflow: hidden;
}

.blog-entry-card.loading {
  opacity: 0 !important;
}

.blog-entry-card:hover {
  transform: scale(1.025);
}

.bec-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--mainWhite);
}

.bec-content.bottom {
  height: fit-content;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 60%;
  height: 60%;
  padding: 10px;
  overflow: hidden;
}

.bec-content.top {
  height: 100%;
  justify-content: center;
  align-items: center;
}

.bec-content-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: rgb(0, 0, 0);
}