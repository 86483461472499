.blog-screen {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  padding-top: calc(1.5*var(--navbarHeight));
  min-height: 100vh;
}

.blog-screen-title {
  font-size: 2.5rem;
  font-weight: normal;
  color: var(--mainWhite);
  margin: 1rem 2rem;
  margin-bottom: 2.25rem;
  z-index: 1;
  text-align: center;
}

.blog-screen-content-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 85%;
  z-index: 1;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 45px;
}

.error-loading-blogs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 2rem;
}

.error-loading-blogs>h2 {
  color: var(--mainWhite);
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

.error-loading-blogs>p {
  color: var(--mainWhite);
  font-size: 1rem;
  text-align: center;
}

.blog-screen-content-container.loading {
  flex: 1;
  align-items: center;
}

@media screen and (max-width: 650px) {
  .blog-screen-title {
    font-size: 2rem;
  }
}